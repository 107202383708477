// Here you can add other styles

@import '~formiojs/dist/formio.full.css';

.diagram-container {
    height:  300px;
  }

.react-bpmn-diagram-container {
  width: 100%;
  height:  100%;
}

.progressStep{
  color: $gray-400;
  width: 35px;
  height: 35px;
  font-size: 16px;
  background-color: $gray-400;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressStep.accomplished {
  background-color: $success-base;
  color: white;
}

.progressStepName{
  color: $primary-dark;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressStepCover{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
 margin-top: 40px;
}

.avatar {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 18px;
  text-align: center;
}